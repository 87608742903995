import React from 'react';
import {
  FaMapMarkerAlt,
  FaEnvelope,
  FaFacebookF,
  FaPhone,
  FaFax,
  FaTwitter,
  FaInstagram,
} from 'react-icons/fa';

import BookTeaser from '../BookTeaser';
import RecentPostSection from './components/RecentPostSection';
import SubscribeBox from './components/SubscribeBox';
// import Search from './components/Search';

import styles from './subFooter.module.scss';

const SubFooter = () => {
  const gray = '#CFCFCF';
  const gold = '#A89888';

  const address = (
    <span>
      3507 North University, Suite&nbsp;200
      <br />
      Hanover Building at Jamestown&nbsp;Square
      <br /> Provo, UT 84604
    </span>
  );
  const mapIcon = <FaMapMarkerAlt size={32} color={gray} />;

  const email = 'hope@mindsetfamilytherapy.com';
  const emailIcon = <FaEnvelope size={26} color={gray} />;

  const phone = '(801) 427-1054';
  const phoneIcon = <FaPhone size={24} color={gray} />;

  const fax = '(855) 221-3659';
  const faxIcon = <FaFax size={24} color={gray} />;

  const fbIcon = <FaFacebookF size={30} color={gold} />;
  const fbUrl = 'https://www.facebook.com/ACTingMinds/';

  const twitterIcon = <FaTwitter size={30} color={gold} />;
  const twitterUrl = 'https://twitter.com/ahagen';

  const instaIcon = <FaInstagram size={30} color={gold} />;
  const instaUrl = 'https://www.instagram.com/imperfectlygood_scrupulosity/';

  return (
    <section className={styles.container}>
      <div className={styles.columnRowWrapper}>
        <div className={styles.column}>
          <h2 className={styles.title}>Contact Us</h2>

          <div className={styles.row}>
            <div className={styles.iconHolder}>{mapIcon}</div>
            <p className={styles.rowContent}>{address}</p>
          </div>

          <div className={styles.row}>
            <div className={styles.iconHolder}>{emailIcon}</div>
            <p className={styles.rowContent}>
              <span className={styles.emailText}>{email}</span>
            </p>
          </div>

          <div className={styles.row}>
            <div className={styles.iconHolder}>{phoneIcon}</div>
            <p className={styles.rowContent}>{phone}</p>
          </div>

          <div className={styles.row}>
            <div className={styles.iconHolder}>{faxIcon}</div>
            <p className={styles.rowContent}>Fax: {fax}</p>
          </div>

          <div className={styles.socialRow}>
            <span>
              <a href={fbUrl} target="_blank" rel="noopener noreferrer">
                {fbIcon}
              </a>
            </span>
            <span>
              <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
                {twitterIcon}
              </a>
            </span>
            <span>
              <a href={instaUrl} target="_blank" rel="noopener noreferrer">
                {instaIcon}
              </a>
            </span>
          </div>
        </div>

        <div className={styles.rightColumn}>
          <h2 className={styles.rightColumnTitle}>Recent Blog Posts</h2>
          <RecentPostSection />
        </div>

        {/* TODO: need to add search capability */}
        {/* <div className={styles.searchBox}> */}
        {/* <h2 className={styles.searchTitle}>Search</h2> */}
        {/* <Search /> */}
        {/* </div> */}
      </div>

      <div className={styles.columnRowWrapper}>
        <div className={styles.column}>
          <div className={styles.bookInfoWrapper}>
            <BookTeaser bookName="Imperfectly Good" whiteBackground />
          </div>
        </div>
        <div className={styles.rightColumn}>
          <h2 className={styles.rightColumnTitle}>Join the Mindset Family Therapy Newsletter</h2>
          <SubscribeBox />
        </div>
      </div>
    </section>
  );
};

export default SubFooter;
