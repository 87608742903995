import React from 'react';
import { Link, navigate } from 'gatsby';
import { Popover, PopoverInteractionKind, Position, Menu, MenuItem } from '@blueprintjs/core';

import styles from './appNav.module.scss';

const aboutMenu = (
  <Menu>
    <MenuItem
      className={styles.menuItem}
      text="Why Mindset Family Therapy?"
      onClick={() => navigate('/philosophy')}
    />
    <MenuItem className={styles.menuItem} onClick={() => navigate('/team')} text="Our Team" />
    <MenuItem
      className={styles.menuItem}
      onClick={() => navigate('/online-psychotherapy')}
      text="Online Psychotherapy"
    />
    <MenuItem
      className={styles.menuItem}
      onClick={() => navigate('/testimonials')}
      text="Testimonials"
    />
    <MenuItem
      className={styles.menuItem}
      onClick={() => navigate('/frequently-asked-questions')}
      text="Frequently Asked Questions"
    />
    <MenuItem className={styles.menuItem} onClick={() => navigate('/press')} text="Press" />
  </Menu>
);

const specialtiesMenu = (
  <Menu>
    <MenuItem text="OCD Spectrum" className={styles.menuItem}>
      <MenuItem
        className={styles.menuItem}
        text="Obsessive-Compulsive Disorder"
        onClick={() => navigate('/specialties/obsessive-compulsive-disorder')}
      />
      <MenuItem
        className={styles.menuItem}
        text="Scrupulosity OCD"
        onClick={() => navigate('/specialties/scrupulosity-ocd')}
      />
    </MenuItem>
    <MenuItem text="Anxiety Disorders" className={styles.menuItem}>
      <MenuItem
        className={styles.menuItem}
        text="Anxiety Treatment"
        onClick={() => navigate('/specialties/anxiety-treatment')}
      />
      <MenuItem
        className={styles.menuItem}
        text="Generalized Anxiety Disorder"
        onClick={() => navigate('/specialties/general-anxiety-disorder')}
      />
      <MenuItem
        className={styles.menuItem}
        text="Panic"
        onClick={() => navigate('/specialties/panic')}
      />
      <MenuItem
        className={styles.menuItem}
        text="Social Phobia"
        onClick={() => navigate('/specialties/social-phobia')}
      />
    </MenuItem>
    <MenuItem text="Relationships" className={styles.menuItem}>
      <MenuItem
        className={styles.menuItem}
        text="Adolescents"
        onClick={() => navigate('/relationships/adolescents')}
      />
      <MenuItem
        className={styles.menuItem}
        text="OCD in Children"
        onClick={() => navigate('/relationships/ocd-in-children')}
      />
    </MenuItem>
    <MenuItem
      className={styles.menuItem}
      text="Mindfulness Training"
      onClick={() => navigate('/specialties/mindfulness')}
    />
    <MenuItem text="Other" className={styles.menuItem}>
      <MenuItem
        className={styles.menuItem}
        text="Depression"
        onClick={() => navigate('/specialties/depression')}
      />
      <MenuItem
        className={styles.menuItem}
        text="Trauma"
        onClick={() => navigate('/specialties/trauma')}
      />
      <MenuItem
        className={styles.menuItem}
        text="Perfectionism"
        onClick={() => navigate('/specialties/perfectionism')}
      />
    </MenuItem>
  </Menu>
);

const booksMenu = (
  <Menu>
    <MenuItem
      className={styles.menuItem}
      text="Imperfectly Good"
      onClick={() => navigate('/books/imperfectly-good')}
    />
    <MenuItem
      className={styles.menuItem}
      text="The Masterpiece Mindset"
      onClick={() => navigate('/books/the-masterpiece-mindset')}
    />
    <MenuItem
      className={styles.menuItem}
      text="Let Go of Anxiety"
      onClick={() => navigate('/books/let-go-of-anxiety')}
    />
    <MenuItem
      className={styles.menuItem}
      text="Free Downloads"
      onClick={() => navigate('/books/downloads')}
    />
  </Menu>
);

const AppNav = () => {
  return (
    <div className={styles.navContainer}>
      <Link className={styles.navMenu} to="/">
        Home
      </Link>

      <Popover
        autoFocus={false}
        content={aboutMenu}
        position={Position.BOTTOM_RIGHT}
        interactionKind={PopoverInteractionKind.HOVER}
        hoverCloseDelay="100"
        hoverOpenDelay="150"
      >
        <div className={styles.navMenu}>About</div>
      </Popover>

      <Popover
        autoFocus={false}
        content={specialtiesMenu}
        position={Position.BOTTOM_RIGHT}
        interactionKind={PopoverInteractionKind.HOVER}
        hoverCloseDelay="100"
        hoverOpenDelay="150"
      >
        <div className={styles.navMenu}>Specialties</div>
      </Popover>

      <Link className={styles.navMenu} to="/groups">
        Groups
      </Link>

      <Link className={styles.navMenu} to="/products">
        Products
      </Link>

      <Popover
        autoFocus={false}
        content={booksMenu}
        position={Position.BOTTOM_RIGHT}
        interactionKind={PopoverInteractionKind.HOVER}
        hoverCloseDelay="100"
        hoverOpenDelay="150"
      >
        <div className={styles.navMenu}>Books</div>
      </Popover>

      <Link className={styles.navMenu} to="/blog">
        Blog
      </Link>

      <Link className={styles.navMenu} to="/contact">
        Contact
      </Link>
    </div>
  );
};

export default AppNav;
