import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import InfoLinkBar from './components/InfoLinkBar';
import AppNav from './components/AppNav';
import IconAppNav from './components/IconAppNav';

import styles from './topBar.module.scss';

const TopBar = () => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "logos/logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 330) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
              originalName
            }
          }
        }
      }
    `}
    render={({ logo }) => {
      return (
        <header className={styles.navContainer}>
          <InfoLinkBar />
          <div className={styles.mainContainer}>
            <div className={styles.leftSide}>
              <Link to="/" className={styles.logo}>
                <Img fluid={logo.childImageSharp.fluid} alt="Mindset Family Therapy" />
                <p>Anxiety &amp; OCD Treatment</p>
              </Link>
            </div>

            <nav className={styles.mainNav}>
              <AppNav />
            </nav>

            <nav className={styles.iconNav}>
              <IconAppNav />
            </nav>
          </div>
        </header>
      );
    }}
  />
);

export default TopBar;
