import striptags from 'striptags';
import _ from 'lodash';
import cheerio from 'cheerio';
import _dateFormat from 'date-fns/format';

export const cleanExcerpt = postContent => {
  const cleanContent = striptags(postContent);
  return cleanContent.substring(0, 750);
};

export const cleanTitle = title => {
  const firstClean = _.replace(striptags(title), '&nbsp;', ' ');
  return _.replace(firstClean, '&#8211;', '-');
};

export const getFeaturedImage = postContent => {
  let img = null;
  const imageRegex = /<img[^>]+src="?([^"\s]+)"?[^>]*\/>/g;
  const result = imageRegex.exec(postContent);
  if (result) img = _.head(_.tail(result));
  return img;
};

export const formatDate = dateString => {
  return _dateFormat(dateString, 'M.D.YY');
};

const allowedTags = [
  'a',
  'p',
  'li',
  'ul',
  'ol',
  'strong',
  'b',
  'i',
  'em',
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'sup',
];

export const cleanContent = rawPostContent => {
  const strippedStepOne = striptags(rawPostContent, allowedTags);
  const step2 = strippedStepOne.replace(/<p>(&nbsp;*?)<\/p>/gi, '');
  return step2.replace(/<p><\/p>/gi, '');
};

export const getVideoIFrame = rawPostContent => {
  const $ = cheerio.load(rawPostContent);
  let videoSourceUrl = null;

  $('iframe').each(function() {
    if ($(this).attr('src')) {
      videoSourceUrl = $(this).attr('src');
    }
    return false;
  });

  if (!_.isNil(videoSourceUrl)) {
    return `<iframe width="100" height="315" src=${videoSourceUrl}?rel=0 frameborder="0" gesture="media" allow="encrypted-media" allowfullscreen />`;
  }
  return null;
};
