import _ from 'lodash';
import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'gatsby';
import { FaCheck } from 'react-icons/fa';

import styles from './subscribeBox.module.scss';

const SubscribeBox = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [status, setStatus] = useState(null);

  const handleFieldInput = (action, errorAction, value) => {
    action(value);
    setStatus(null);

    if (!_.isNil(errorAction)) {
      errorAction(false);
    }
  };

  const clearForm = () => {
    setName('');
    setEmail('');
  };

  const validateFields = () => {
    let isValid = true;

    // Validate actual email address
    if (!emailError) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(_.trim(email))) {
        setEmailError(true);
        isValid = false;
      }
    }
    if (!_.trim(name)) {
      setNameError(true);
      isValid = false;
    }

    return isValid;
  };

  const onSubmit = async () => {
    const isValid = validateFields();
    if (!isValid) {
      return;
    }

    setStatus('In Progress');

    const subData = {
      name: _.trim(name),
      email: _.trim(email),
    };

    try {
      const result = await axios.post('/.netlify/functions/subscribe', subData);

      if (_.get(result, 'data.success')) {
        setStatus('Success');
      } else if (!result.data.success && result.data.error === 'Email already exists') {
        setStatus('Already Subscribed');
      } else if (!result.data.success && _.get(result, 'data.error')) {
        setStatus('Unknown');
      }
      clearForm();
    } catch (error) {
      setStatus('Error');
    }
  };

  return (
    <div className={styles.subscriptionBoxWrapper}>
      <div className={styles.description}>
        Join the newsletter to stay up-to-date with the latest articles from Mindset
        Family&nbsp;Therapy.
      </div>
      <span className={styles.inputLabel}>Name (required)</span>
      <input
        type="text"
        placeholder="Name"
        value={name}
        className={nameError ? styles.errorBorder : styles.validBorder}
        onChange={e => handleFieldInput(setName, setNameError, e.target.value)}
      />

      <span className={styles.inputLabel}>Email (required)</span>
      <input
        type="text"
        placeholder="Email"
        value={email}
        className={emailError ? styles.errorBorder : styles.validBorder}
        onChange={e => handleFieldInput(setEmail, setEmailError, e.target.value)}
      />
      <button className={styles.subscribeButton} type="button" onClick={onSubmit}>
        SUBSCRIBE
      </button>

      {status && (
        <div className={styles.messageCenter}>
          {status === 'Error' && (
            <div className={styles.errorMessage}>
              Oops! Something went wrong. Please try&nbsp;again.
            </div>
          )}
          {status === 'In Progress' && <div>Joining newsletter...</div>}
          {status === 'Success' && (
            <div className={styles.success}>
              <FaCheck size={18} color="#4E839B" />
              <span>
                You{`'`}ve successfully joined the newsletter!
                <br />
                Don{`'`}t forget to also check out these{' '}
                <Link to="/books/downloads">free downloads</Link>.
              </span>
            </div>
          )}
          {status === 'Already Subscribed' && (
            <div>
              Looks like you{`'`}ve already subscribed! If you{`'`}re not receiving the newsletter,
              contact our team.
              <br />
              <br />
              In the meantime, check out these <Link to="/books/downloads">free downloads</Link>.
            </div>
          )}
          {status === 'Unknown' && (
            <div>
              Hmm. Not sure what happened but contact our team to re-join the newsletter.
              <br />
              <br />
              In the meantime, check out these <Link to="/books/downloads">free downloads</Link>.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SubscribeBox;
