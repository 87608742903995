import React from 'react';
import { navigate } from 'gatsby';
import { Popover, PopoverInteractionKind, Position, Menu, MenuItem } from '@blueprintjs/core';
import { FaBars } from 'react-icons/fa';

import styles from './iconAppNav.module.scss';

const mobileMenu = (
  <Menu>
    <MenuItem className={styles.menuItem} onClick={() => navigate('/')} text="Home" />
    <MenuItem className={styles.menuItem} onClick={() => navigate('/about')} text="About" />
    <MenuItem className={styles.menuItem} onClick={() => navigate('/team')} text="Our Team" />
    <MenuItem
      className={styles.menuItem}
      onClick={() => navigate('/testimonials')}
      text="Testimonials"
    />
    <MenuItem
      className={styles.menuItem}
      onClick={() => navigate('/specialties')}
      text="Specialties"
    />
    <MenuItem className={styles.menuItem} onClick={() => navigate('/groups')} text="Groups" />
    <MenuItem className={styles.menuItem} onClick={() => navigate('/products')} text="Products" />
    <MenuItem className={styles.menuItem} onClick={() => navigate('/books')} text="Books" />
    <MenuItem className={styles.menuItem} onClick={() => navigate('/blog')} text="Blog" />
    <MenuItem className={styles.menuItem} onClick={() => navigate('/contact')} text="Contact" />
  </Menu>
);

const IconAppNav = () => {
  const menuIcon = <FaBars size={26} color="#757575" />;

  return (
    <div className={styles.iconNavContainer}>
      <Popover
        content={mobileMenu}
        position={Position.LEFT_TOP}
        interactionKind={PopoverInteractionKind.CLICK}
        hoverCloseDelay="100"
        hoverOpenDelay="150"
      >
        {menuIcon}
      </Popover>
    </div>
  );
};

export default IconAppNav;
