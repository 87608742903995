import React from 'react';
import { Link } from 'gatsby';
import { FaFacebookF, FaInstagram, FaTwitter, FaEnvelope, FaPhone, FaLaptop } from 'react-icons/fa';

import styles from './infoLinkBar.module.scss';

const InfoLinkBar = () => {
  const white = '#FFF';
  const lightBlue = '#A7C0CC';

  const email = 'hope@mindsetfamilytherapy.com';
  const emailIcon = <FaEnvelope size={15} color={white} />;

  const phone = '(801) 427-1054';
  const phoneIcon = <FaPhone size={13} color={white} />;

  const fbIcon = <FaFacebookF size={15} color={lightBlue} />;
  const fbUrl = 'https://www.facebook.com/ACTingMinds/';

  const twitterIcon = <FaTwitter size={15} color={lightBlue} />;
  const twitterUrl = 'https://twitter.com/ahagen';

  const instaIcon = <FaInstagram size={18} color={lightBlue} />;
  const instaUrl = 'https://www.instagram.com/imperfectlygood_scrupulosity/';

  const portal = 'Client Portal Login';
  const portalIcon = <FaLaptop size={17} color={white} />;
  const portalUrl = 'https://mindsetfamilytherapy.clientsecure.me';

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.contact}>
          <div>
            <a
              className={styles.contactItem}
              href={portalUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {portalIcon}
              <span style={{ marginLeft: 12 }}>{portal}</span>
            </a>
          </div>

          <Link className={styles.contactItem} to="/contact">
            {phoneIcon}
            <span style={{ marginLeft: 12 }}>{phone}</span>
          </Link>

          <Link className={styles.contactItem} to="/contact">
            {emailIcon}
            <span style={{ marginLeft: 12 }}>{email}</span>
          </Link>
        </div>

        <div className={styles.links}>
          <span className={styles.displayAltIcon}>
            <a href={portalUrl} target="_blank" rel="noopener noreferrer">
              {portalIcon}
            </a>
          </span>
          <span className={styles.displayAltIcon}>
            <Link to="/contact">{phoneIcon}</Link>
          </span>
          <span className={styles.displayAltIcon}>
            <Link to="/contact">{emailIcon}</Link>
          </span>
          <span>
            <a href={fbUrl} target="_blank" rel="noopener noreferrer">
              {fbIcon}
            </a>
          </span>
          <span>
            <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
              {twitterIcon}
            </a>
          </span>
          <span>
            <a href={instaUrl} target="_blank" rel="noopener noreferrer">
              {instaIcon}
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};

export default InfoLinkBar;
